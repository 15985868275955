/*
-------------------------------------------------
Google Fonts lokal einbinden
-------------------------------------------------
*/

// Source Sans Pro
@include font-face("Source Sans Pro", "../fonts/source_sans/source-sans-pro-v12-latin_latin-ext-regular", ("eot","ttf", "woff2", "woff","svg")
) {
  font-style: normal;
  font-weight: 400;
}
@include font-face("Source Sans Pro", "../fonts/source_sans/source-sans-pro-v12-latin_latin-ext-600", ("eot","ttf", "woff2", "woff","svg")
) {
  font-style: normal;
  font-weight: 600;
}

// @include font-face("Source Sans Pro", "../fonts/source_sans/source-sans-pro-v12-latin_latin-ext-italic", ("eot","ttf", "woff2", "woff","svg")
// ) {
//   font-style: italic;
//   font-weight: 400;
// }
// @include font-face("Source Sans Pro", "../fonts/source_sans/source-sans-pro-v12-latin_latin-ext-600italic", ("eot","ttf", "woff2", "woff","svg")
// ) {
//   font-style: italic;
//   font-weight: 600;
// }
