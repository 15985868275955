// ================================================
// LAYOUT Header
// Theme Beisst der?
// ================================================

#header {
  background-color: $bg-color-invers;
  color: $color-invers;
  grid-area: header;
}
