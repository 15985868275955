//unsichtbare Element, aber fuer Screenreader sichtbar (Uebernahme aus Contao Framework bzw. von html5-boilerplate)


@mixin hide ($hide: true) {
  @if $hide {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  @else {
    border: 0;
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    padding: 0;
    position: static;
    width: auto;
  }
}

