// ================================================
// LAYOUT container
// Theme Beisst der?
// 25 Spalten
// ================================================

#container {
  position: relative;
  grid-area: container;
  padding: 0 $grid-global-small;
  display: grid;
  width: $width-content;
  max-width: $max-width-content;
  margin: 0 auto;
}
