/* verticaler Rhythmus Hilfslinien*/


@mixin vertical_rhythm($rhythm: 1.5rem) {
  background-image: linear-gradient(rgba(200, 50, 50, .25) 1px, transparent 1px);
  background-size: 1px $rhythm;
  background-position:  0 0;
  // @include large {
  //   background-size: 1px 26px;
  // }
}
