// ================================================
// COMPONENTS LOogo
// Theme Beisst der?
// ================================================

.logo {
  font-size: $font-size-large;
  letter-spacing: 0.05rem;
  font-weight:  600;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 $grid-global-small $distance $grid-global-small;
  border-bottom: 3px solid $color-border-invers;
  @include small {
    flex-flow: row;
    justify-content: flex-start;
    padding: 0 $grid-global;

  }
  p {
    padding: 0 $distance;
    margin: 0;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  img {
    display: block;
    flex: 0 0 auto;
    order: -1;
    margin: 0 0 1rem 0;
    @include small {
      order: 0;
      padding: 0 $distance;
      border-left: 3px solid $color-border-invers;
      border-right: 3px solid $color-border-invers;
      margin: 0;
    }
  }

}
