// ===========================================
// Grid (design + production helper)
// 27-Spalten-Grid/18 Spalten
// ========================================

html #wrapper:before {
	content: "";
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 6000px;
	z-index: 9999;
	pointer-events: none;
	background-image: linear-gradient(to left, rgba(red,1) (100% * 1 / 18), rgba(red,0) (100% * 1 / 18), rgba(red,0) (100% * 2 / 18), rgba(red,1) (100% * 2 / 18), rgba(red,1) (100% * 3 / 18), rgba(red,0) (100% * 3 / 18), rgba(red,0) (100% * 4 / 18), rgba(red,1) (100% * 4 / 18), rgba(red,1) (100% * 5 / 18), rgba(red,0) (100% * 5 / 18), rgba(red,0) (100% * 6 / 18), rgba(red,1) (100% * 6 / 18), rgba(red,1) (100% * 7 / 18), rgba(red,0) (100% * 7 / 18), rgba(red,0) (100% * 8 / 18), rgba(red,1) (100% * 8 / 18), rgba(red,1) (100% * 9 / 18), rgba(red,0) (100% * 9 / 18), rgba(red,0) (100% * 10 / 18), rgba(red,1) (100% * 10 / 18), rgba(red,1) (100% * 11 / 18), rgba(red,0) (100% * 11 / 18), rgba(red,0) (100% * 12 / 18), rgba(red,1) (100% * 12 / 18), rgba(red,1) (100% * 13 / 18), rgba(red,0) (100% * 13 / 18), rgba(red,0) (100% * 14 / 18), rgba(red,1) (100% * 14 / 18), rgba(red,1) (100% * 15 / 18), rgba(red,0) (100% * 15 / 18), rgba(red,0) (100% * 16 / 18), rgba(red,1) (100% * 16 / 18), rgba(red,1) (100% * 17 / 18), rgba(red,0) (100% * 17 / 18));
	opacity: 0.1;
	}
@include small {
	html #wrapper:before {
		background-image: linear-gradient(to left, rgba(red,1) 100% * 1 / 27, rgba(red,0) (100% * 1 / 27), rgba(red,0) (100% * 2 / 27), rgba(red,1) (100% * 2 / 27), rgba(red,1) (100% * 3 / 27), rgba(red,0) (100% * 3 / 27), rgba(red,0) (100% * 4 / 27), rgba(red,1) (100% * 4 / 27), rgba(red,1) (100% * 5 / 27), rgba(red,0)(100% * 5 / 27), rgba(red,0) (100% * 6 / 27), rgba(red,1) (100% * 6 / 27), rgba(red,1)(100% * 7 / 27), rgba(red,0) (100% * 7 / 27), rgba(red,0) (100% * 8 / 27), rgba(red,1) (100% * 8 / 27), rgba(red,1) (100% * 9 / 27), rgba(red,0) (100% * 9 / 27), rgba(red,0) (100% * 10 / 27), rgba(red,1) (100% * 10 / 27), rgba(red,1) (100% * 11 / 27), rgba(red,0) (100% * 11 / 27), rgba(red,0) (100% * 12 / 27), rgba(red,1) (100% * 12 / 27), rgba(red,1) (100% * 13 / 27), rgba(red,0) (100% * 13 / 27), rgba(red,0) (100% * 14 / 27), rgba(red,1) (100% * 14 / 27), rgba(red,1) (100% * 15 / 27), rgba(red,0) (100% * 15 / 27), rgba(red,0) (100% * 16 / 27), rgba(red,1) (100% * 16 / 27), rgba(red,1) (100% * 17 / 27), rgba(red,0) (100% * 17 / 27), rgba(red,0) (100% * 18 / 27), rgba(red,1) (100% * 18 / 27), rgba(red,1) (100% * 19 / 27), rgba(red,0) (100% * 19 / 27), rgba(red,0) (100% * 20 / 27), rgba(red,1) (100% * 20 / 27), rgba(red,1) (100% * 21 / 27), rgba(red,0) (100% * 21 / 27), rgba(red,0) (100% * 22 / 27), rgba(red,1) (100% * 22 / 27), rgba(red,1) (100% * 23 / 27), rgba(red,0) (100% * 23 / 27), rgba(red,0) (100% * 24 / 27), rgba(red,1) (100% * 24 / 27), rgba(red,1) (100% * 25 / 27), rgba(red,0) (100% * 25 / 27), rgba(red,0) (100% * 26 / 27), rgba(red,1) (100% * 26 / 27));
	}
}
// @include xlarge {
// 	html #container:before {
// 		content: "";
// 		position: absolute;
// 		display: block;
// 		top: 0;
// 		left: 0;
// 		width: 100%;
// 		height: 6000px;
// 		z-index: 9999;
// 		pointer-events: none;
// 		background-image: linear-gradient(to left, rgba(green,1) 100% * 1 / 25, rgba(green,0) (100% * 1 / 25), rgba(green,0) (100% * 2 / 25), rgba(green,1) (100% * 2 / 25), rgba(green,1) (100% * 3 / 25), rgba(green,0) (100% * 3 / 25), rgba(green,0) (100% * 4 / 25), rgba(green,1) (100% * 4 / 25), rgba(green,1) (100% * 5 / 25), rgba(green,0)(100% * 5 / 25), rgba(green,0) (100% * 6 / 25), rgba(green,1) (100% * 6 / 25), rgba(green,1)(100% * 7 / 25), rgba(green,0) (100% * 7 / 25), rgba(green,0) (100% * 8 / 25), rgba(green,1) (100% * 8 / 25), rgba(green,1) (100% * 9 / 25), rgba(green,0) (100% * 9 / 25), rgba(green,0) (100% * 10 / 25), rgba(green,1) (100% * 10 / 25), rgba(green,1) (100% * 11 / 25), rgba(green,0) (100% * 11 / 25), rgba(green,0) (100% * 12 / 25), rgba(green,1) (100% * 12 / 25), rgba(green,1) (100% * 13 / 25), rgba(green,0) (100% * 13 / 25), rgba(green,0) (100% * 14 / 25), rgba(green,1) (100% * 14 / 25), rgba(green,1) (100% * 15 / 25), rgba(green,0) (100% * 15 / 25), rgba(green,0) (100% * 16 / 25), rgba(green,1) (100% * 16 / 25), rgba(green,1) (100% * 17 / 25), rgba(green,0) (100% * 17 / 25), rgba(green,0) (100% * 18 / 25), rgba(green,1) (100% * 18 / 25), rgba(green,1) (100% * 19 / 25), rgba(green,0) (100% * 19 / 25), rgba(green,0) (100% * 20 / 25), rgba(green,1) (100% * 20 / 25), rgba(green,1) (100% * 21 / 25), rgba(green,0) (100% * 21 / 25), rgba(green,0) (100% * 22 / 25), rgba(green,1) (100% * 22 / 25), rgba(green,1) (100% * 23 / 25), rgba(green,0) (100% * 23 / 25), rgba(green,0) (100% * 24 / 25), rgba(green,1) (100% * 24 / 25));
// 		opacity: 0.1;
// 	}
// }

html:hover #wrapper:before,
html:hover #container:before {
	visibility: hidden;
}
