// ================================================
// COMPONENTS Navigation
// ThemeBeisst der?
// ================================================

nav {
  ul {
    list-style-type: none;
  }
  ul {
    margin: 0;
    padding: 0;
  }
}

.nav_main {
  font-size: $font-size-large;
  padding: $distance $grid-global-small;
  @include small {
    padding: $distance $grid-global;
  }
  @include xlarge {

  }
  .level_1 {
    font-weight: 600;
    @include medium {
      display: flex;
      justify-content: flex-start;
    }
    > li {
      text-align: center;
      padding-bottom: 1rem;
      border-bottom: 1px solid $color-border-accent-alternative;
      &:last-child {
      border-bottom: none;
      }
      @include medium {
        text-align: left;
        border: none;
        padding-right: $distance;
        &:first-child {

        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    a {
      color: $color-invers;
    }
    a:hover,
    a.trail,
    strong {
      color: $color-accent-alternative;
    }
    a,
    strong {
      position: relative;
      // &.submenu {
      //   padding-right: $distance;
        // &:after {
        //   content: ">";
        //   position: absolute;
        //   right: 0;
        // }
      // }
    }
  }
  .level_2 {
    margin-left: $distance;
    @include medium {
      display: none;
    }
  }
}

.nav_meta {
  font-size: $font-size-small;
  padding: $distance $grid-global-small;
  @include medium {
    padding: $distance $grid-global;
  }
  .level_1 {
    display: flex;
    justify-content: flex-end;
    > li {
      padding: 1rem 0.5rem;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    a {
      color: $color-invers;
    }
    strong,
    a:hover {
      color: $color-accent-alternative;
    }
  }
}
