// ================================================
// LAYOUT CUSTOM
// Theme Beisst der?
// 7 Spalten
// ================================================

#subnav {
  grid-area: subnav;
  @include medium {
    padding-left: 1 / 7 * 100%;
    border-left: $border;
  }
}
