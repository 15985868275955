/*
--------------------------------------------------------------------------------
GENERAL/CONTAO_RESET
Uebernahme einiger von Contao verwendeter Einstellungen
ggf. mit eigenen Anpassungen
 --------------------------------------------------------------------------------
 */
//default Allgemeine Klassen

$distance: 1rem !default;
$baseline: 1.625rem !default;

//Floating
.float_left,
.float_right {
  margin-bottom: $baseline;
}
.float_left {
  @include medium {
    float: left;
    margin-right: 2 * $baseline;
  }
}
.float_right {
  @include medium {
    float: right;
    margin-left: 2 * $baseline;
  }
}


//Clearing
.clear {
  @include medium {
    height:0.1px;
    font-size:0.1px;
    line-height:0.1px;
    clear:both;
  }
}
.block {
  // overflow:hidden; // stoert haeufig, wenn so allgemein eingesetzt
}


// nur fuer Screenreader lesbar
// based on HTML5 Boilerplate:
// github.com/h5bp/html5-boilerplate/issues/194#issuecomment-564745
.invisible {
  @include hide;
}

div {
  &.back,
  &.more {
    margin-top: $distance;
  }
}
