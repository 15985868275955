// ================================================
// LAYOUT Wrapper
// Theme Beisst der?
// ================================================

#wrapper {
  min-width: $min-width-page;
  min-height: 100vh;
  width: $width-page;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr 90px;
  grid-template-areas:
    "header"
    "subnav"
    "container"
    "footer";
  grid-row-gap: 2rem;
  @include medium {
    // grid-column-gap: $grid-global;
    grid-template-columns: $grid-global 1fr (7 * $grid-global) $grid-global;
    grid-template-rows: 182.5px 1fr 90px;
    grid-template-areas:
      "header header header header"
      ". container subnav ."
      "footer footer footer footer"
  }
}
