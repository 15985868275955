// ================================================
// LAYOUT Footer
// Theme Beisst der?
// ================================================

#footer {
  grid-area: footer;
  background-color: $bg-color-invers;
  color: $color-invers;
}
