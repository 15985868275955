/*
================================================================================
Settings - Beisst der?
Autor: Maren Lange
================================================================================
*/

// Layout
$min-width-page         : 320px;
// $max-width-page         : 100%;
$width-page             : 100%;
$width-content: 100%;
$max-width-content          : 75rem;

$distance: 1rem;
$grid-global: 1 /27 * 100%;
$grid-main: 1 /25 * 100%;
$grid-global-small: 1 /18 * 100%;
$grid-main-small: 1 /14 * 100%;
// Breakpoints
$b-xxsmall     : 20em; // 320px
$b-xxsmall-max : 29.9em;
$b-xsmall      : 30em; // 480px
$b-xsmall-max  : 37.999em;
$b-small       : 38em; // 608px
$b-small-max   : 47.999em;
$b-medium      : 48em; //768px
$b-medium-max  : 63.999em;
$b-large       : 64em; // 1024px
$b-large-max   : 74.999em;
$b-xlarge      : 75em; // 1200px
$b-xlarge-max  : 119.999em;
$b-xxlarge     : 120em; // 1920px

// Typography global
$sans-serif           : 'Source Sans Pro', sans-serif;
$font-family          : $sans-serif;
$font-family-headline : $font-family;

// Fluid Typography
// $screen-min      : 25em;
// $screen-max      : 90em;
// $basefont-min    : 1rem; // 16px
// $basefont-min    : 1.125rem; // 18px
// $basefont-max    : 1.125rem; // 18px
// $basefont-max    : 1.25rem; // 20px

// $baseline        : 1.625rem;
// $line-height: $baseline / 1rem;
//
// $font-size-xsmall : 0.625rem;
$font-size-small : 0.88rem;
$font-size-normal: 1rem;
$font-size-large : 1.2rem;
// $font-size-xlarge : 1.5rem;
//
$font-weight: 400;
$font-weight-bold: 600;


// Headlines
// $font-size-h1: 1.5rem;
// $font-size-h2: 1.3125rem;
// $font-size-h3: 1.25rem;
// $font-size-h4: 1rem;
// $font-size-h5: 1rem;
// $font-size-h6: 1rem;
//
// $font-weight-headline : 400;
// $font-style-header-special : italic;
// $font-size-header-special : 0.875em;

// Color-Schema
  // How to use color variables:
  // https://www.mediaevent.de/tutorial/farbcodes.html
  // https://www.w3schools.com/colors/colors_names.asp
  // http://www.colors.commutercreative.com
  // http://davidwalsh.name/sass-color-variables-dont-suck
  // http://chir.ag/projects/name-that-color/
  // http://www.color-blindness.com/color-name-hue/
  // TODO Welche Farben werden genutzt? Einsatz Farbschatierungen verbessern/veraendern

// Colorset
$nero: #1F1600;
$pizazz:#ff9501;
$yellow-sea: #fcb203;
$bright-turquoise: #02c2ff;
// Background Colors global
$bg-color             : white;
// $bg-color-alternative      : $kabul;
$bg-color-accent : $bright-turquoise;
// $bg-color-accent : $bright-turquoise;
$bg-color-invers      : $pizazz;
$bg-color-invers-alternative: $yellow-sea;
// $bg-color-error: $jewel;


// Colors global
$color                : $nero;
$color-invers         : white;
$color-accent    : $bright-turquoise;
$color-accent-alternative: lighten($yellow-sea, 20%);
// $color-invers-alternative: $yellow-green;
// $color-error: whitesmoke;

//Link Colors und Button Colors
$color-link            : $color-accent;
$color-link-link       : $color-link;
$color-link-visited    : $color-link;
// $color-link-hover      : $kabul;
// $color-link-focus      : $color-link-hover;
// $color-link-active     : $color-link-hover;
// // $color-button-hover : $color-link-hover !default;

// Borders and Shadows
// $color-border: black;
// $color-border-alternative: $kabul;
$color-border-invers: white;
$color-border-accent         : $pizazz;
$color-border-accent-alternative: $color-accent-alternative;
$border                : 1px solid $color-border-accent;
// $border-radius         : 5px;


// Metanavigation
// $color-nav-meta         : $concrete;
// $color-nav-meta-hover   : #fff;
// $color-nav-meta-current : $color-nav-meta-hover;

// Form
// $bg-form-field      : lighten($suva-gray, 35%);
// $bg-form-field-focus: lighten($suva-gray, 20%);
// $border-form-field  : 1px solid $suva-gray;
// $bg-submit: url('../img/bg_submit.png') no-repeat left center transparent;
